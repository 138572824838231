import React from 'react';

type QAProps = {
  questions: { question: string; answer: string }[];
};

const QAComponent: React.FC<QAProps> = ({ questions }) => {
  return (
    <div className="qa-container">
      {questions.map((qa, index) => (
        <div className="qa-card" key={index}>
          <div className="qa-question" id={`question-${index}`}>
            <h2 className='back_title'>{qa.question}</h2>
          </div>
          <div
            className="qa-answer"
            id={`answer-${index}`}
            dangerouslySetInnerHTML={{ __html: qa.answer }} // Corrected usage of dangerouslySetInnerHTML
          />
        </div>
      ))}
    </div>
  );
};

export default QAComponent;
