import React, { useState } from 'react';
import '../css/Distribution.css';

interface DistributionProps {
  data: {
    distribution_image: string;
    distribution_image_link: string;
    distribution_html: string;
    distribution: string;
    distribution_citation: string;
    citation: string;
  };
}

const SpeciesDistribution: React.FC<DistributionProps> = ({ data }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseClick = () => {
    setIsFullScreen(false);
  };

  return (
    <div className="species-container">
      <div className="species-image-section">
        <img
          src={data.distribution_image}
          alt="species Distribution"
          className="species-image"
          onClick={handleImageClick}
        />
        <p className="species-image-link">
          {data.distribution_image_link}
        </p>
      </div>
      <div className="species-content">
        <div className="species-distribution-html" dangerouslySetInnerHTML={{ __html: data.distribution_html }} />
        <p className="species-distribution">
          {data.distribution}
        </p>
        <div className="species-distribution-citation" dangerouslySetInnerHTML={{ __html: data.distribution_citation }} />
        <p className="species-citation">
          {data.citation}
        </p>
      </div>

      {isFullScreen && (
        <div className="fullscreen-overlay">
          <button className="close-button" onClick={handleCloseClick}>×</button>
          <img
            src={data.distribution_image}
            alt="species Distribution"
            className="fullscreen-image"
          />
        </div>
      )}
    </div>
  );
};

export default SpeciesDistribution;
