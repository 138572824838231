import React from 'react';
import '../css/introductionCard.css';

interface IntroductionCardProps {
  introduction: string; // HTML content as a string
}

const IntroductionCard: React.FC<IntroductionCardProps> = ({ introduction }) => {
  return (
    <div className="intro-card">
      <div className="intro-content" dangerouslySetInnerHTML={{ __html: introduction }} />
    </div>
  );
};

export default IntroductionCard;
