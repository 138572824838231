import React, { useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import "../css/newsFeed.css";

interface Props {
  firebaseApp: FirebaseApp;
  dateday: string;
}

interface ArticleData {
  title: string;
  sentiment: number;
  summary: string;
  why_grade: string;
  positive_words: string[];
  negative_words: string[];
  neutral_words: string[];
  labels: string[];
  category: string[];
  url: string;
  id: string;
}

const NewsFeed: React.FC<Props> = ({ firebaseApp, dateday }) => {
  const [articles, setArticles] = useState<ArticleData[]>([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const db = getFirestore(firebaseApp);
      const sentimentCollectionRef = collection(db, 'sentiment-analysis/'+dateday+'/articles');
      const sentimentSnapshot = await getDocs(sentimentCollectionRef);

      const articlesData: ArticleData[] = sentimentSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      } as ArticleData));
      setArticles(articlesData);
    };

    fetchArticles();
  }, [firebaseApp]);

  return (
    <div className="news-feed">
      <h1 className="title">News Feed - {dateday}</h1>

      {articles.length > 0 ? (
        articles.map((article) => (
          <div className="article-card" key={article.id}>
            <h2 className="article-title">{article.title}</h2>
            <div className="sentiment-slider-container">
              <input
                type="range"
                min="0"
                max="100"
                value={article.sentiment}
                className="sentiment-slider"
                readOnly
              />
            </div>
            <p className="summary"><strong>Summary:</strong> {article.summary}</p>
            <p className="why-grade"><strong>Why Grade:</strong> {article.why_grade}</p>
            <div className="word-lists">
              <div><strong>Positive Words:</strong> {article.positive_words.join(', ')}</div>
              <div><strong>Negative Words:</strong> {article.negative_words.join(', ')}</div>
              <div><strong>Neutral Words:</strong> {article.neutral_words.join(', ')}</div>
            </div>
            <div className="labels-category">
              <div><strong>Labels:</strong> {article.labels.join(', ')}</div>
              <div><strong>Category:</strong> {article.category.join(', ')}</div>
            </div>
            <div>Sentiment: {article.sentiment}</div>
            <a className="article-link" href={"/sentiment-analysis/"+dateday+"/article/"+article.id} target="_blank" rel="noopener noreferrer">
              Read more
            </a>
          </div>
        ))
      ) : (
        <p className="loading-text">Loading articles...</p>
      )}
    </div>
  );
};

export default NewsFeed;


