import React from 'react';
import '../css/pinterestSaveButton.css';
import pinImage from "../css/pinterest_logo.png"
import { title } from 'process';
interface PinterestSaveButtonProps {
  url: string;
  description: string;
  media: string;
  title: string;
}

const PinterestSaveButton: React.FC<PinterestSaveButtonProps> = ({ url, description, media, title }) => {
  const saveToPinterest = () => {
    const pinUrl = `https://pinterest.com/pin/create/button/?title=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}&description=${encodeURIComponent(description)}&media=${encodeURIComponent(media)}`;
    window.open(pinUrl, '_blank');
  };

  return (
    <button className="pinterest-save-button" onClick={saveToPinterest}>
      <img style={{height:"22px"}} className="pinterest-icon" src={pinImage} alt="Pinterest Logo" />
      <span>Save</span>
    </button>
  );
};

export default PinterestSaveButton;
