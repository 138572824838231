import React, { useEffect, useState } from "react";
import { FirebaseApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import '../css/Timer.css'; // Import the CSS file

interface AirdropCountdownProps {
  firebaseApp: FirebaseApp;
}

const AirdropCountdown: React.FC<AirdropCountdownProps> = ({ firebaseApp }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const firestore = getFirestore(firebaseApp);
    const fetchAirdropDate = async () => {
      const docRef = doc(firestore, "airdrop", "1");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const airdropDate = docSnap.data().airdrop_date.toDate(); // Convert Firestore timestamp to JS Date
        const intervalId = setInterval(() => {
          updateTimeLeft(airdropDate);
        }, 1000);

        return () => clearInterval(intervalId);
      }
    };

    fetchAirdropDate();
  }, [firebaseApp]);

  const updateTimeLeft = (airdropDate: Date) => {
    const now = new Date();
    const difference = airdropDate.getTime() - now.getTime();

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  return (
    <div className="countdown-container">
      <div className="time-unit">
        <span>{timeLeft.days}</span>
        <p>Days</p>
      </div>
      <div className="time-unit">
        <span>{timeLeft.hours}</span>
        <p>Hours</p>
      </div>
      <div className="time-unit">
        <span>{timeLeft.minutes}</span>
        <p>Minutes</p>
      </div>
      <div className="time-unit">
        <span>{timeLeft.seconds}</span>
        <p>Seconds</p>
      </div>
    </div>
  );
};

export default AirdropCountdown;
