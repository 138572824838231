import React, { useState, useEffect } from 'react';
import { FirebaseApp } from 'firebase/app';
import { User } from 'firebase/auth';
import { Firestore, collection, query, where, limit, getDocs } from 'firebase/firestore';
import "../css/userContributions.css";

interface ContributionData {
  text_to_contribute: string;
  approved: boolean;
  authors: string[];
  date: string;
  title: string;
  title_of_the_container?: string;
  edition?: string;
  publisher?: string;
  doi_or_url?: string;
  is_doi?: boolean;
  contributionType: string;
  slug: string;
  category: string;
  editors?: string[];
  urlOrPrint?: string;
  isBookPrint?: boolean;
  pageNumbers?: string;
  journalVolume?: string;
  journalIssue?: string;
}

interface UserContributionsProps {
  firebaseApp: FirebaseApp;
  uid: string;
  firestore: Firestore;
}

const UserContributions: React.FC<UserContributionsProps> = ({ firebaseApp, uid, firestore }) => {
  const [contributions, setContributions] = useState<ContributionData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchContributions = async () => {
      if (!uid) return;

      try {
        const q = query(
          collection(firestore, 'contributions'),
          where('uid', '==', uid),
          limit(10)
        );
        const querySnapshot = await getDocs(q);
        const fetchedContributions: ContributionData[] = querySnapshot.docs.map(doc => doc.data() as ContributionData);
        setContributions(fetchedContributions);
      } catch (error) {
        console.error('Error fetching contributions: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContributions();
  }, [uid, firestore]);

  if (loading) {
    return <div>Loading contributions...</div>;
  }

  if (contributions.length === 0) {
    return <div>No contributions found.</div>;
  }

  return (
    <div className="contributions-container">
      <h2>Your Contributions</h2>
      {contributions.map((contribution, index) => (
        <div key={index} className="contribution-card">
          <h3>{contribution.title}</h3>
          <p><strong>Category:</strong> {contribution.category}</p>
          <p><strong>Type:</strong> {contribution.contributionType}</p>
          <p><strong>Text:</strong> {contribution.text_to_contribute}</p>
          <p><strong>Authors:</strong> {contribution.authors.join(', ')}</p>
          {contribution.editors && <p><strong>Editors:</strong> {contribution.editors.join(', ')}</p>}
          <p><strong>Date:</strong> {contribution.date}</p>
          {contribution.doi_or_url && (
            <p><strong>{contribution.is_doi ? 'DOI' : 'URL'}:</strong> {contribution.doi_or_url}</p>
          )}
          <p className={contribution.approved ? 'approved' : 'pending'}>
            Status: {contribution.approved ? 'Approved' : 'Pending'}
          </p>
        </div>
      ))}
    </div>
  );
};

export default UserContributions;
