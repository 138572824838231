import React from 'react';
import ReactPlayer from 'react-player';
import '../css/videoGallery.css';

interface VideoGalleryProps {
  urls: string[];
  descriptions: string[];
}

const VideoGallery: React.FC<VideoGalleryProps> = ({ urls, descriptions }) => {
    console.log(descriptions)

    return (<div className="gallery-container">
      {urls.map((url, index) => (
        <div className="video-card" key={index}>
          <div className="player-wrapper">
            <ReactPlayer playing={true} className="styled-react-player" url={url} width="100%" height="100%" controls={true}   config={{
          file: {
            attributes: {
              autoPlay: true,
              loop: true
            }
          }
        }}
      />
          </div>
          <div className="description">{descriptions[index]}</div>
        </div>
      ))}
    </div>
  );
};

export default VideoGallery;
