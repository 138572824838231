import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/ScientificClassificationTable.css';
import Card from './CardConcept';

interface ScientificClassificationProps {
  classification: {
    Domain: string;
    Kingdom: string;
    Phylum: string;
    Class: string;
    Order: string;
    Suborder: string;
    Superfamily: string;
    Family: string;
    Subfamily: string;
    Genus: string;
    Species: string;
  };
}

const fetchJsonData = async (url: string) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching JSON data:', error);
    return [];
  }
};

const ScientificClassificationTable: React.FC<ScientificClassificationProps> = ({ classification }) => {
  const [concepts, setConcepts] = useState<any[]>([]);

  useEffect(() => {
    const url = 'https://pink-neat-turtle-227.mypinata.cloud/ipfs/QmPqM4Ffw6DNdJbSvVVf21MohwpeUeNHEbBN8J6ZPHBWax';
    fetchJsonData(url).then((data) => setConcepts(data));
  }, []);

  const order = [
    'Domain',
    'Kingdom',
    'Phylum',
    'Class',
    'Order',
    'Suborder',
    'Superfamily',
    'Family',
    'Subfamily',
    'Genus',
    'Species'
  ];

  const findConcept = (name: string) => {
    return concepts.find((concept) => concept.concept === name);
  };

  return (
    <div className="table-container">
      <table className="classification-table">
        <thead className='thead_specie'>
          <tr>
            <th className='thtaxon'>Rank</th>
            <th className='thtaxon2'>Taxon</th>
          </tr>
        </thead>
        <tbody className='tbody'>
          {order.map(rank => (
            <tr className='trtaxon' key={rank}>
              <td>{rank}</td>
              <td>
                {findConcept(classification[rank as keyof typeof classification]) ? (
                <Card
                    link={findConcept(classification[rank as keyof typeof classification]).link}
                    title={classification[rank as keyof typeof classification]}
                    image={findConcept(classification[rank as keyof typeof classification]).image}
                    description={findConcept(classification[rank as keyof typeof classification]).definition}
                  />
                ) : (
                  <span>{classification[rank as keyof typeof classification]}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScientificClassificationTable;
