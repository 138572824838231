import React, { useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import '../css/quiz.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import MatrixEffect from './Matrix';
interface QuizProps {
  firebaseApp: FirebaseApp;
}

interface QuizData {
  question1: string;
  options1: string[];
  correct1: number;
  question2: string;
  options2: string[];
  correct2: number;
  question3: string;
  options3: string[];
  correct3: number;
}

const Quiz: React.FC<QuizProps> = ({ firebaseApp }) => {
  const [quizData, setQuizData] = useState<QuizData | null>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([ -1, -1, -1 ]);
  const [score, setScore] = useState<number | null>(null);
  const auth = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
       
        const fetchQuizData = async () => {
          let currentpathName = window.location.pathname;
          console.log("pathname: "+ currentpathName)
          // /p/1
          let slug= "";
          if (currentpathName.startsWith("/quiz/")){
             slug = currentpathName.substring(6, currentpathName.length)
            console.log("slug: "+slug)
       
          }
    
          const quizDoc = await getDoc(doc(db, 'quiz', slug));
          if (quizDoc.exists()) {
            setQuizData(quizDoc.data() as QuizData);
          } else {
            console.error('Quiz not found');
          }
        };
    
        fetchQuizData();
        // ...
      } else {
        // User is signed out
        // ...
        window.location.replace("/signup/");
      }
    });


    



    const fetchQuizData = async () => {
      const db = getFirestore(firebaseApp);
      let currentpathName = window.location.pathname;
      console.log("pathname: "+ currentpathName)
      // /p/1
      let slug= "";
      if (currentpathName.startsWith("/quiz/")){
         slug = currentpathName.substring(6, currentpathName.length)
        console.log("slug: "+slug)
   
      }
      const quizDoc = await getDoc(doc(db, 'quiz', slug));
      if (quizDoc.exists()) {
        setQuizData(quizDoc.data() as QuizData);
      } else {
        console.error('Quiz not found');
      }
    };

    fetchQuizData();
  }, [firebaseApp]);

 const calculateScore = () => {
    if (quizData) {
      let newScore = 0;
      if (selectedAnswers[0] === quizData.correct1) newScore++;
      if (selectedAnswers[1] === quizData.correct2) newScore++;
      if (selectedAnswers[2] === quizData.correct3) newScore++;
      setScore(newScore);
      saveQuizResults(newScore);
    }
  };

  const saveQuizResults = async (newScore: number) => {
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);


      let currentpathName = window.location.pathname;
      console.log("pathname: "+ currentpathName)
      // /p/1
      let slug= "";
      if (currentpathName.startsWith("/quiz/")){
         slug = currentpathName.substring(6, currentpathName.length)
        console.log("slug: "+slug)
   
      }


      const quizField = `quiz_${slug}`;
      const quizData = {
        [quizField]: newScore
      };

      if (userDoc.exists()) {
        // Update existing document
        await updateDoc(userDocRef, quizData);
      } else {
        // Create new document
        await setDoc(userDocRef, quizData);
      }
    } else {
      console.error('No authenticated user found');
    }
  };

  if (!quizData) {
    return <div>Loading...</div>;
  }

  const handleOptionClick = (questionIndex: number, optionIndex: number) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[questionIndex] = optionIndex;
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <div className="quiz-container">
             <div id="matrix">
          <div className="matrix-overlay"></div>
        </div>
      <h1 className="quiz-title"> Quiz (Quantum Compass) </h1>
      <span>*Earn points soon Solana Tokens</span>
      <div className="question">
        <h2>{quizData.question1}</h2>
        {quizData.options1.map((option, index) => (
          <button
            key={index}
            className={`option-button ${selectedAnswers[0] === index ? 'selected' : ''}`}
            onClick={() => handleOptionClick(0, index)}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="question">
        <h2>{quizData.question2}</h2>
        {quizData.options2.map((option, index) => (
          <button
            key={index}
            className={`option-button ${selectedAnswers[1] === index ? 'selected' : ''}`}
            onClick={() => handleOptionClick(1, index)}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="question">
        <h2>{quizData.question3}</h2>
        {quizData.options3.map((option, index) => (
          <button
            key={index}
            className={`option-button ${selectedAnswers[2] === index ? 'selected' : ''}`}
            onClick={() => handleOptionClick(2, index)}
          >
            {option}
          </button>
        ))}
      </div>
      <button className="submit-button" onClick={calculateScore}>
        Submit Quiz
      </button>
      <div>
      {/* Your quiz UI and logic here */}
    </div>
      {score !== null && <div className="score">Your score: {score} / 3</div>}
    </div>
  );
};

export default Quiz;
