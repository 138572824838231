import React, { useEffect, useState } from "react";
import { FirebaseApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import "../css/Leaderboard.css";
import Timer from "./AirdropCountdown";
interface LeaderboardProps {
  firebaseApp: FirebaseApp;
}

interface User {
  name: string;
  points: number;
  cNFTs: number;
  totalPoints: number; // Added totalPoints field to store the calculated total
}

const Leaderboard: React.FC<LeaderboardProps> = ({ firebaseApp }) => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, "leaderboard", "9-11-2024");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const fetchedUsers: User[] = Object.values(data.users).map((user: any) => {
          const preTotal = user.points; // Pretotal is just the base points
          const totalPoints = preTotal + preTotal * (user.cNFTs * 0.01); // Calculate total points with 1% increase for each cNFT

          return {
            name: user.name,
            points: user.points,
            cNFTs: user.cNFTs,
            totalPoints: totalPoints, // Store total points
          };
        });

        // Sort users by total points in descending order
        const sortedUsers = fetchedUsers.sort((a, b) => b.totalPoints - a.totalPoints);
        setUsers(sortedUsers);
      } else {
        console.log("No such document!");
      }
    };

    fetchLeaderboard();
  }, [firebaseApp]);

  return (
    <div className="leaderboard-container">
      <h1>Leaderboard</h1>
      <ul className="leaderboard-list">
        {users.map((user, index) => (
          <li key={index} className="leaderboard-item">
            <span className="leaderboard-rank">#{index + 1}</span>
            <span className="leaderboard-name">{user.name}</span>
            <span className="leaderboard-points">
              {user.points} points
            </span>
            <br></br>
            <span className="leaderboard-cnfts">
               (<strong>{user.cNFTs}</strong> NFTs holding)
            </span>
            <span className="leaderboard-total" style={{ color: "green" }}>
              Total points: {user.totalPoints.toFixed(2)}
            </span>
          </li>

        ))}
        <div className="container_video_image"><h1 className="back_title">Next Aidrop Countdown ⏳</h1></div>
        <h3>For members of the leaderboard (all users that solve Free Scientific Quizzes)</h3>
                <Timer firebaseApp={firebaseApp}></Timer>

      </ul>
    </div>
    
  );
};

export default Leaderboard;
