// Category.tsx
import React, { useEffect, useState } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getFirestore, collection, getDocs, limit, query } from 'firebase/firestore';
import "../css/categories.css"
interface CategoryProps {
  app: FirebaseApp;
}

interface CategoryData {
  id: string;
  name: string;
  description: string;
  image: string;
}

const Categories: React.FC<CategoryProps> = ({ app }) => {
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore(app);
        const q = query(collection(db, 'categories'), limit(10));
        const querySnapshot = await getDocs(q);

        const categoriesList: CategoryData[] = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as CategoryData[];

        setCategories(categoriesList);
      } catch (err) {
        setError('Failed to load categories.');
        console.error('Error fetching categories:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [app]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1 className='h1_category'>Categories</h1>
      <ul className='ul_category'>
        {categories.map(category => (
          <a href={"/category/"+category.name}>
          <li className='li_category' key={category.id}>
            <img className="img_category" src={category.image}></img>
            <h2 className='h2_category'>{category.name}</h2>
            <p className="p_category">{category.description}</p>
          </li>
          </a>
        ))}
      </ul>
    </div>
  );
};

export default Categories;
