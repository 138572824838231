import React, { useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { User } from 'firebase/auth';
import { addDoc, collection, Firestore } from 'firebase/firestore'; // Firestore
import "../css/addContribution.css";

interface AddContributionProps {
  firebaseApp: FirebaseApp;
  firebaseUser: User | undefined;
  firestore: Firestore;
  slug: string | undefined;
}

const AddContribution: React.FC<AddContributionProps> = ({ firebaseApp, firebaseUser, firestore, slug }) => {
  const [showCard, setShowCard] = useState(false);
  const [category, setCategory] = useState('');
  const [contributionType, setContributionType] = useState('');
  const [textToContribute, setTextToContribute] = useState('');
  const [authors, setAuthors] = useState([{ name: '' }]);
  const [editors, setEditors] = useState([{ name: '' }]);
  const [organization, setOrganization] = useState(false);
  const [date, setDate] = useState('');
  const [title, setTitle] = useState('');
  const [edition, setEdition] = useState('');
  const [publisher, setPublisher] = useState('');
  const [urlOrDoi, setUrlOrDoi] = useState('');
  const [isDoi, setIsDoi] = useState(false);
  const [pageNumbers, setPageNumbers]= useState("")
  const [isBookPrint, setIsBookPrint] = useState(false)
  const [title_of_the_container, setTitle_of_the_container] = useState("");
  const [urlOrPrint, setUrlOrPrint] = useState("");
  const [journalVolume, setJournalVolume] = useState("");
  const [journalissue, setJournalIssue] = useState("");

  const handleSubmit = async () => {
    const contributionData = {
      text_to_contribute: textToContribute,
      approved: false,
      authors: authors.map(a => a.name),
      date,
      title,
      title_of_the_container,
      edition,
      publisher,
      doi_or_url: urlOrDoi,
      is_doi: isDoi,
      contributionType,
      uid: firebaseUser!.uid,
      slug,
      category,
      editors: editors.map(e => e.name),
      urlOrPrint, 
      isBookPrint
    };
    const contributionDataDictionaryEntry = {
      text_to_contribute: textToContribute,
      approved: false,
      authors: authors.map(a => a.name),
      date,
      title,
      title_of_the_container,
      edition,
      publisher,
      contributionType,
      uid: firebaseUser!.uid,
      slug,
      category,
      editors: editors.map(e => e.name),
      urlOrPrint, 
      isBookPrint,
      pageNumbers
    };
    const contributionDataJournal = {
      text_to_contribute: textToContribute,
      approved: false,
      authors: authors.map(a => a.name),
      date,
      title,
      title_of_the_container,
      edition,
      publisher,
      contributionType,
      uid: firebaseUser!.uid,
      slug,
      category,
      editors: editors.map(e => e.name),
      urlOrPrint, 
      isBookPrint,
      pageNumbers, 
      journalVolume, 
      journalissue
    };
    const contributionNewsPaperArticle = {
      text_to_contribute: textToContribute,
      approved: false,
      authors: authors.map(a => a.name),
      date,
      title,
      title_of_the_container,
      contributionType,
      uid: firebaseUser!.uid,
      slug,
      category,
      editors: editors.map(e => e.name),
      urlOrPrint, 
      isBookPrint
        };
if (contributionType =="dictionary_entry"){
  try {
    await addDoc(collection(firestore, 'contributions'), contributionDataDictionaryEntry);
    alert('Contribution added successfully');
    setShowCard(false); // Hide form after submission
  } catch (e) {
    console.error('Error adding contribution:', e);
  }
}else if(contributionType == "journal_article"){


  try {
    await addDoc(collection(firestore, 'contributions'), contributionDataJournal);
    alert('Contribution added successfully');
    setShowCard(false); // Hide form after submission
  } catch (e) {
    console.error('Error adding contribution:', e);
  }

}
//contributionNewsPaperArticle
else if(contributionType == "newspaper_article"){


  try {
    await addDoc(collection(firestore, 'contributions'), contributionNewsPaperArticle);
    alert('Contribution added successfully');
    setShowCard(false); // Hide form after submission
  } catch (e) {
    console.error('Error adding contribution:', e);
  }

}
else{
  try {
    await addDoc(collection(firestore, 'contributions'), contributionData);
    alert('Contribution added successfully');
    setShowCard(false); // Hide form after submission
  } catch (e) {
    console.error('Error adding contribution:', e);
  }
}

  };

  const handleAddAuthor = () => {
    if (authors.length < 3) {
      setAuthors([...authors, { name: '' }]);
    }
  };

  const handleRemoveAuthor = (index: number) => {
    const updatedAuthors = authors.filter((_, i) => i !== index);
    setAuthors(updatedAuthors);
  };

  const handleAuthorChange = (index: number, value: string) => {
    const updatedAuthors = authors.map((author, i) =>
      i === index ? { name: value } : author
    );
    setAuthors(updatedAuthors);
  };

  const handleAddEditor = () => {
    if (editors.length < 2) {
      setEditors([...editors, { name: '' }]);
    }
  };

  const handleRemoveEditor = (index: number) => {
    const updatedEditors = editors.filter((_, i) => i !== index);
    setEditors(updatedEditors);
  };

  const handleEditorChange = (index: number, value: string) => {
    const updatedEditors = editors.map((editor, i) =>
      i === index ? { name: value } : editor
    );
    setEditors(updatedEditors);
  };

  return (
    <div className="contribution-container">
      <button className="add-button" onClick={() => setShowCard(true)}>
        Add a Contribution
      </button>
      {showCard && (
        <div className="contribution-card">
          <h3>Contribution Details</h3>
          <label>Choose a Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select</option>
            <option value="Science Facts">Science Facts</option>
            <option value="Fact Sheet">Fact Sheet</option>
            <option value="General">General</option>
            <option value="Distribution">Distribution</option>
            <option value="Documentary">Documentary</option>
            <option value="Art">Art</option>
            <option value="Anatomy">Anatomy</option>
          </select>

          <label>Contribution Type</label>
          <select
            value={contributionType}
            onChange={(e) => setContributionType(e.target.value)}
          >
            <option value="">Select</option>
            <option value="book">Book</option>
            <option value="journal_article">Journal Article</option>
            <option value="video">Video</option>
            <option value="webpage">Webpage</option>
            <option value="newspaper_article">Newspaper Article</option>
            <option value="dictionary_entry">Dictionary Entry</option>
          </select>

          <textarea
            className="text-area"
            value={textToContribute}
            onChange={(e) => setTextToContribute(e.target.value)}
            placeholder="Text to contribute"
          ></textarea>

          <label>Authors or Organizations</label>
          {authors.map((author, index) => (
            <div key={index} className="author-field">
              <input
                type="text"
                value={author.name}
                onChange={(e) => handleAuthorChange(index, e.target.value)}
              />
              <button onClick={() => handleRemoveAuthor(index)}>Remove</button>
            </div>
          ))}
          {authors.length < 3 && (
            <button className="add-author" onClick={handleAddAuthor}>
              Add Author
            </button>
          )}

          <label>Date of Publication / Retrieved / (n.d.) for no date </label>
          <input
            type="text"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="YYYY, Month DD"
          />

          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          {contributionType === 'book' && (
            <>
              <label>Edition</label>
              <input
                type="text"
                value={edition}
                onChange={(e) => setEdition(e.target.value)}
              />

              <label>Publisher</label>
              <input
                type="text"
                value={publisher}
                onChange={(e) => setPublisher(e.target.value)}
              />
            </>
          )}
{contributionType !== 'dictionary_entry' && contributionType != "webpage" && contributionType != "newspaper_article" && contributionType!= "video" &&(
  <>
    <label>DOI or URL</label>
    <select
      value={isDoi ? 'doi' : 'url'}
      onChange={(e) => setIsDoi(e.target.value === 'doi')}
    >
      <option value="url">URL</option>
      <option value="doi">DOI</option>
    </select>
    <input
      type="text"
      value={urlOrDoi}
      onChange={(e) => setUrlOrDoi(e.target.value)}
    />
  </>
)}
{contributionType === 'dictionary_entry' || contributionType ==='newspaper_article'  && (
  <>
    <label>URL or Print</label>
    <select
      value={isBookPrint ? 'print' : 'url'}
      onChange={(e) => setIsBookPrint(e.target.value === 'print')}
    >
      <option value="url">URL</option>
      <option value="print">PRINT</option>
    </select>
   
  </>
)}
{isBookPrint === true && (
  <>
    <label>The page number(s) of the work</label>
   
    <input
      type="text"
      value={pageNumbers}
      onChange={(e) => setPageNumbers(e.target.value)}
    />
  </>
)}

{contributionType === "journal_article" && (
  <>
    <label>The page number(s) of the work</label>
   
    <input
      type="text"
      value={pageNumbers}
      onChange={(e) => setPageNumbers(e.target.value)}
    />
  </>
)}
{isBookPrint === false || contributionType!== "journal_article" && (
  <>
    <label>The url of the work</label>
   
    <input
      type="text"
      value={urlOrPrint}
      onChange={(e) => setUrlOrPrint(e.target.value)}
    />
  </>
)}
{contributionType=== "webpage" && (
  <>
    <label>The url of the work</label>
   
    <input
      type="text"
      value={urlOrDoi}
      onChange={(e) => setUrlOrDoi(e.target.value)}
    />
  </>
)}

{contributionType === 'journal_article'  && (
  <>
    <label>Journal Volume</label>

<input
      type="text"
      value={journalVolume}
      onChange={(e) => setJournalVolume(e.target.value)}
    />
        <label>Journal Issue</label>

<input
      type="text"
      value={journalissue}
      onChange={(e) => setJournalIssue(e.target.value)}
    />
  </>
)}

{ contributionType==="webpage" 
          &&  (
            <>
             <label>Title of the Container Holding the Work(e.g. book title, journal title, website name)</label>
             <input
            type="text"
            value={title_of_the_container}
            onChange={(e) => setTitle_of_the_container(e.target.value)}
          /><br/>
        
            </>
          )}
          {contributionType === 'dictionary_entry' ||  contributionType==="webpage" || contributionType==="newspaper_article" 
          || contributionType==="video" || contributionType=="journal_article" &&  (
            <>
             <label>Title of the Container Holding the Work(e.g. book title, journal title, website name)</label>
             <input
            type="text"
            value={title_of_the_container}
            onChange={(e) => setTitle_of_the_container(e.target.value)}
          /><br/>
        
            </>
          )}

  {contributionType === 'dictionary_entry' &&(
  <>
  <label>Editor or Translator</label>
         
         {editors.map((editor, index) => (
           <div key={index} className="editor-field">
             <input
               type="text"
               value={editor.name}
               onChange={(e) => handleEditorChange(index, e.target.value)}
             />
             <button onClick={() => handleRemoveEditor(index)}>Remove</button>
           </div>
         ))}
         {editors.length < 2 && (
           <button className="add-editor" onClick={handleAddEditor}>
             Add Editor/Translator
           </button>
         )}

  </>

  )}







          <button className="submit-button" onClick={handleSubmit}>
            Submit Contribution
          </button>
        </div>
      )}
    </div>
  );
};

export default AddContribution;
