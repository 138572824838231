import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import Gerber3DViewer from './Gerber3DViewer';
import ScientificClassificationTable from './ScientificClassificationTable';
import '../css/ScientificClassificationTable.css';
import AnatomyComponent from './Anatomy';
import '../css/article.css';
import allCNFTs from '../allcNFTs.json';
import Cookies from 'js-cookie';

interface BlogPostProps {
  app: FirebaseApp;
  validCNFTs: number
}

interface ScientificClassificationProps {

      Domain: string;
      Kingdom: string;
      Phylum: string;
      Class: string;
      Order: string;
      Suborder: string;
      Superfamily: string;
      Family: string;
      Subfamily: string;
      Genus: string;
      Species: string;

}
interface PostData {
  title: string;
  image: string;
  description: string;
  author: string;
  date: Timestamp;
  modelUrl: string;
  conservation: string;
  youtubeID: string;
  model3DZip: string;
  scientificClassification: ScientificClassificationProps;
  anatomy: {title:string, image:string, src:string, author: string, type: string, license: string, license_url: string}

}

const PinterestPost2: React.FC<BlogPostProps> = ({ app,validCNFTs }) => {
  const [post, setPost] = useState<PostData | null>(null);
  const [model3DZip, setModel3DZip] = useState("")
  const [loading, setLoading] = useState(true);
  const { slug } = useParams<{ slug: string }>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [zipFilename, setZipFilename] = useState("3D-Quantumcompass.xyz")
  const [classIsDisabled, setClassIsDisabled] = useState("isDisabled");
  const [error, setError] = useState<string | null>(null);
  const [NFTsURL, setNFTsURL] = useState("#");
  const db = getFirestore(app);
  const auth = getAuth(app);
  interface DatacNFT {
    cNFTs: string[];
  }
  
      const postData = async (url: string, data: PostData): Promise<void> => {
        try {
          const response = await fetch(url, {
            method: 'POST', // Method type
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data) // Convert the TypeScript object into a JSON string
          });
      
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          // You can process the response further if needed
          const result = await response.json();
        
          let counterValidI= 0
          const officialAllCNFTs2: DatacNFT = allCNFTs;

          for (var i =0; i<result.length; i++){
            //console.log("cNFTs: "+ result[i].id)
            for (var e=0; e<officialAllCNFTs2.cNFTs.length; e++){
// console.log(officialAllCNFTs[e]+"")
              if(result[i].id==officialAllCNFTs2.cNFTs[e]){
                console.log("valid")
counterValidI+=1;
              }
            }
          }

        
          console.log('Success:', result);
        } catch (error) {
          console.error('Error:', error);
        }
      };




  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, 'posts', slug!);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          setPost(docSnap.data() as PostData);
          setModel3DZip(docSnap.data().model3DZip)
          setNFTsURL(docSnap.data().NFTsURL)
          setZipFilename(docSnap.data().title+"-3D_QuantumCompass.zip")
        } else {
          setError('No such document!');
        }
      } catch (err) {
        setError(`Error getting document: ${err}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
 
    //check if owns
    console.log("validCNFs: "+validCNFTs)
    if (validCNFTs>0){
      setIsDisabled(false)
      setClassIsDisabled("isNotDisabled")
    }


    const checkCookie = () => {
      let currentpathName = window.location.pathname;
      console.log("pathname: "+ currentpathName)
      // /p/1
      let slug= "";
      if (currentpathName.startsWith("/animal/")){
         slug = currentpathName.substring(8, currentpathName.length)
        console.log("slug: "+slug)
   
      }
      const validCNFTs = Cookies.get('validCNFTs_'+slug);
      if (validCNFTs && parseInt(validCNFTs) > 0) {
        setIsDisabled(false);
        setClassIsDisabled("isNotDisabled");
      } else {
        setIsDisabled(true);
        setClassIsDisabled("isDisabled");
      }
    };

    // Check the cookie every 5 seconds
    const interval = setInterval(checkCookie, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);


  }, [slug, db]);

  useEffect(() => {
    const checkAuthState = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsDisabled(false);
          setClassIsDisabled("isNotDisabled");
        } else {
          setIsDisabled(true);
          setClassIsDisabled("isDisabled");
        }
      });
    };

    checkAuthState();
  }, [auth,validCNFTs]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>No post found</div>;
  }

  // Convert Firestore Timestamp to JavaScript Date
  const postDate = post.date.toDate();

  const order = [
    'Domain',
    'Kingdom',
    'Phylum',
    'Class',
    'Order',
    'Suborder',
    'Superfamily',
    'Family',
    'Subfamily',
    'Genus',
    'Species'
];

  async function downloadZipFile(url: string, filename: string): Promise<void> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error downloading file: ${response.statusText}`);
      }
  
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error while downloading the ZIP file:', error);
    }
  }
  
  // Usage example
  

  function downloadFile(){
    downloadZipFile(model3DZip, zipFilename);

  }
  


  return (
    <div>
      <h1>3D Model</h1>
      <Gerber3DViewer url={post.modelUrl} />
      
      <button onClick={downloadFile} disabled={isDisabled} className={classIsDisabled}>Download 3D Model</button>
      <span className='info'> To download the 3D model <a href='/signup/'>signup</a> or buy an <a href={NFTsURL}>NFT</a></span>
      <br></br>
      <br></br>
      <h1>{post.title}</h1>

      <div className='container_video_image'>

  <br></br>

  <img className="image_animal" src={post.image} alt={post.title} />



</div>
<div className='container_video_image'>

{post.anatomy!= undefined &&post.anatomy!= null?<div>
          <h1 className='back_title'>Anatomy</h1>
       <AnatomyComponent anatomy={post.anatomy}></AnatomyComponent>
        </div>
:<div></div>}
</div>
      {post.scientificClassification!= undefined &&post.scientificClassification!= null?<div><h1>Scientific Classification</h1><br></br>
    
        <ScientificClassificationTable classification={post.scientificClassification}></ScientificClassificationTable>
        </div>:<></>}
      
      <div dangerouslySetInnerHTML={{ __html: post.description }} />
      <p>
        <div dangerouslySetInnerHTML={{ __html: post.conservation }} />
        <strong>author: www.quantumcompass.xyz</strong> - {postDate.toLocaleDateString()}
      </p>
    </div>
  );
};

export default PinterestPost2;
