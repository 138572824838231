import React, { useState } from 'react';
import "../css/conceptCard.css"
interface CardProps {
  title: string;
  image: string;
  description: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ title, image, description, link }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="card-container">
      <div
        className="text-element"
        
      >
        <span onMouseEnter={() => setShowPopup(true)}>{title}</span>
      </div>
      {showPopup && (
        <div className="popup" onMouseLeave={() => setShowPopup(false)}>
          <div className="popup-content">
            <button className="close-button" onClick={() => setShowPopup(false)}>×</button>
            <a href={link}>
            <img src={image} alt={title} className="popup-image" />
            <h1 className="popup-title">{title}</h1>
            <p className="popup-description">{description}</p>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
