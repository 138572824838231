// Category.tsx
import React, { useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, DocumentData } from 'firebase/firestore';
import "../css/category.css";
import { useParams } from 'react-router-dom';
import {User} from 'firebase/auth';

interface CategoryProps {
  app: FirebaseApp;
  user: User | undefined;
  userDoc: DocumentData | undefined;
}

interface CategoryData {
  id: string;
  title: string;
  description: string;
  image: string;
}
interface QuizzesSolved { [key: string]: boolean }
const Category: React.FC<CategoryProps> = ({ app, user, userDoc }) => {
  const { slug } = useParams<{ slug: string }>();

  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [slugCategory, setSlugCategory] = useState("animal");
  const [quizzesSolvedO, setQuizzesSolvedO] = useState<QuizzesSolved>() 

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore(app);
        let q= null;
        console.log(slug)
        if (slug =="animals"){
          setSlugCategory("animal")
         q = query(collection(db, 'posts'), where('category', '==', 'animals'));
        }
        if (slug =="documentaries"){
          q = query(collection(db, 'documentaries'));
          setSlugCategory("documentary")


        }
        const querySnapshot = await getDocs(q!);


        let quizzesSolved:QuizzesSolved = {}; // Explicitly type the object

        for (const key in userDoc) {
          if (key.startsWith('quiz_') && typeof userDoc[key] === 'number') {
            let slugS=key.substring(5,key.length)+""
            quizzesSolved![slugS] =true
          }
        }
        setQuizzesSolvedO(quizzesSolved)
    
   
        const categoriesList: CategoryData[] = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as CategoryData[];

        setCategories(categoriesList);
      } catch (err) {
        setError('Failed to load categories.');
        console.error('Error fetching categories:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [userDoc]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="containerCat">
      <h1>{slug}</h1>
      <ul className="category-list">
        {categories.map(category => (
        <a href={"/"+slugCategory+"/"+category.id}>
            <li className="li_category" key={category.id}>
            <img className="img_category" src={category.image} alt={category.title} />
            <h2 className="h2_category">{category.title}</h2>
            {quizzesSolvedO != undefined && quizzesSolvedO[category.id]!= undefined && quizzesSolvedO[category.id]==true?<div id="quiz-solved"><strong>✅</strong> Quizz</div>:<></>}
          </li></a> 
        ))}
      </ul>
    </div>
  );
};

export default Category;
