import React, { useState } from 'react';
import { useEffect } from "react";
import { getAuth, signOut,onAuthStateChanged,sendEmailVerification } from 'firebase/auth';
import { FirebaseApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc, setDoc } from "firebase/firestore";
import UserContributions from './UserContributions';
interface BlogPostProps {
    app: FirebaseApp
  }


  const Profile: React.FC<BlogPostProps> = ({  app }) => {
    const auth = getAuth(app);
    const navigate = useNavigate();
    const [user, setUser] = useState();
const [uid, setUid] = useState("");
const [emailVerifiedB, setEmailVerifiedB] = useState(false)
const [userName, setUserName] = useState("")
const [userDocument, setUserDocument] = useState(false)
const db = getFirestore(app)
    useEffect(() => {

      async function getUser(emailVerified: boolean, emailS: string,uid:string){
        const docRef = doc(db, "users", uid!);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setUserDocument(true)
          console.log("Document data:", docSnap.data());
          if (docSnap.data().username != null && docSnap.data().username!= undefined && docSnap.data().username!= ""){
            setUserName(docSnap.data().username)
          }

          if (emailVerified && emailS!= ""){
            //if not saved on firestore
            if (docSnap.data().email ==null || docSnap.data().email == undefined || docSnap.data().email ==""){
              // Add a new document in collection "cities"
await updateDoc(doc(db, "users", uid!), {
 email: emailS,
 emailVerified
});
            }

          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
          setUserDocument(false)
if (emailVerified){
          await setDoc(doc(db, "users", uid!), {
            email: emailS,
            emailVerified
           });
        }
      }}
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid+"";
          setUid(uid)
        
          const emailVerified = user.emailVerified;
          setEmailVerifiedB(emailVerified)
let emailS= "";
          if (emailVerified){
emailS = auth.currentUser!.email+"";
          }
          //read snapshot user
         getUser(emailVerified, emailS, uid)

          //if email verified
     
          // ...
        } else {
          // User is signed out
          // ...
          window.location.replace("/signup/");

        }
      });
        },[]);
        
        function SendAVerificationEmail(){
          if (auth.currentUser!= null){
        
            sendEmailVerification(auth.currentUser)
  .then(() => {
    // Email verification sent!
    // ...
    alert("email sent to: "+auth.currentUser!.email)
  });


        }
      }
  const handleSignOut = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log('Sign-out successful.');
      navigate('/signup/');

    }).catch((error) => {
      // An error happened.
      console.error('An error happened during sign-out:', error);
    });
  };
  async function saveUserName(){
    if (!userDocument){
// Add a new document in collection "cities"
await setDoc(doc(db, "users", uid!), {
 username: userName
});
    }else{
      console.log(uid+", "+userName)
      await updateDoc(doc(db, "users", uid!), {
        username: userName
       }); 
    }
  }

  return (
    <div >
      <div className='container_video_image'>

            <input style={{height:"49px"}} onChange={e=> setUserName(e.target.value)} value={userName} placeholder='username'></input> <br></br>   
            <button onClick={saveUserName} >Save Username</button> <br/><br/>
            </div>
            <div className='container_video_image back_title'>

<p><strong>Your temporary ID: </strong>{uid}</p>
      {emailVerifiedB?<>✅Email Verified</>:<button onClick={SendAVerificationEmail}>Send verification Email</button>}
      <br/><br/>
      </div>
      <div className='container_video_image back_title'>

      <button onClick={handleSignOut}>Sign Out</button>
      </div>
      <div className='container_video_image'>

        <h1 className='back_title'>Contributions</h1>
      </div>
      <div className='container_video_image'>

       <UserContributions firebaseApp={app} uid={uid} firestore={db}></UserContributions>
      </div>

    </div>
  );
};

export default Profile;
