import React from 'react';
import { FirebaseApp } from 'firebase/app';

interface Props {
  firebaseApp: FirebaseApp;
}

const Mint: React.FC<Props> = ({ firebaseApp }) => {
  return <h2>Mint and claim 50k Quantum Compass Tokens</h2>;
};

export default Mint;
