import React, { Suspense, useRef, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF, useAnimations } from '@react-three/drei';
import { ErrorBoundary } from 'react-error-boundary';
import * as THREE from 'three';
import "../css/render3D.css"
interface ModelProps {
  url: string;
}

const Model: React.FC<ModelProps> = ({ url }) => {
  const { scene, animations } = useGLTF(url);
  const { actions } = useAnimations(animations, scene);
  const ref = useRef<THREE.Group>();

  useEffect(() => {
    if (actions) {
      // Play all animations (you can choose specific ones if needed)
    
        Object.values(actions).forEach((action) => {
            if (action != null){
                action.play()
            }
            });

      
    }
  }, [actions]);

  // Animation hook to rotate the model
  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.01;
    }
  });

  return <primitive ref={ref} object={scene} />;
};

const FallbackComponent = ({ error }: { error: Error }) => {
  console.error('Error loading model:', error);
  return <div>Error loading model: {error.message}</div>;
};

interface Gerber3DViewerProps {
  url: string;
}

const Gerber3DViewer: React.FC<Gerber3DViewerProps> = ({ url }) => {
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const validateUrl = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          setIsValidUrl(false);
          setErrorMessage(`Error: Unable to fetch the model. Status: ${response.status}`);
        } else {
          const contentType = response.headers.get('content-type');
          console.log(`Content type: ${contentType}`); // Debugging line
          if (contentType && !contentType.includes('model/gltf-binary')) {
            setIsValidUrl(false);
            setErrorMessage(`Error: Content type is not model/gltf-binary, but ${contentType}`);
          } else {
            setIsValidUrl(true);
          }
        }
      } catch (error) {
        setIsValidUrl(false);
        setErrorMessage(`Error:`);
      }
    };
    validateUrl();
  }, [url]);

  if (!isValidUrl) {
    return <div>{errorMessage}</div>;
  }

  return (
    <div className="render3D" style={{ height: '50vh' }}>
      <Canvas
        camera={{ position: [90, 100, 5], fov: 75 }} // Adjust camera position and field of view
        onCreated={({ gl }) =>
          gl.domElement.addEventListener('webglcontextlost', (event) => {
            event.preventDefault();
            console.error('WebGL context lost.');
          })
        }
      >
        <Suspense fallback={null}>
          <ambientLight intensity={1} />
          <pointLight position={[10, 10, 10]} />
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Model url={url} />
          </ErrorBoundary>
          <OrbitControls />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default Gerber3DViewer;
